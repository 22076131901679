/*
 * @Description:用户端-底部我的-反馈建议-我要反馈 myFeedBack
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-24 12:51:18
 * @LastEditors: LinFeng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 样例请求post
export function demoPost (param) {
  return request({
    url: prefix + '/demo',
    method: 'post',
    data: param
  })
}

// 新增
export function addFeedBack (param) {
  return request({
    url: prefix + '/feedback/addFeedBack',
    method: 'post',
    data: param
  })
}

// 上传图片
export function upFile (param) {
  return request({
    url: prefix + '/file/upFile',
    method: 'post',
    data: param
  })
}
