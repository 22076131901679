/*
 * @Description:停车开票 parkInvoice
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-11 10:46:53
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'
const prefix = '/mobile'

// 获取可开票订单
export function getPaymentRecordToInvoice (param) {
  return request({
    url: prefix + '/payment/getPaymentRecordToInvoice',
    method: 'get',
    data: param
  })
}

// 申请开票 个人 单位
export function invoice (param) {
  return request({
    url: prefix + '/invoice/invoice',
    method: 'post',
    data: param
  })
}

// 重新获取 开票结果 刷新开票状态
export function getInvoiceAgain (param) {
  return request({
    url: prefix + '/invoice/getInvoiceAgain',
    method: 'post',
    data: param
  })
}
