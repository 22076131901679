/*
 * @Description:计费规则详情 billRuleDetail
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-11 19:43:43
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/bill'
const prefix1 = '/order'

// 测试支付
export function testAppPay (param) {
  return request({
    url: prefix + '/test/testAppPay',
    method: 'post',
    data: param
  })
}

// 根据计费规则id获取计费规则
export function queryBillingRuleById (param) {
  return request({
    url: prefix + '/BillingRuleController/queryBillingRuleById',
    method: 'post',
    data: param
  })
}

// 订单已支付
export function completeOrderFree (param) {
  return request({
    url: prefix1 + '/OrderController/completeOrderFree' + '/' + param.orderSequence + '/' + param.billingRuleDefId,
    method: 'get',
    data: param
  })
}
