/*
 * @Description:计费规则 biliingRules
 * @Author: GeChang
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-26 20:24:46
 * @LastEditors: GeChang
 */
import request from '@/utils/request'

const prefix = '/bill'

// 根据计费ID查计费规则详情
export function queryBillingRulesById (param) {
  return request({
    url: prefix + '/BillingRuleController/queryBillingRuleById',
    method: 'post',
    data: param
  })
}
