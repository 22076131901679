/*
 * @Description: 请求接口入口
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 20:39:39
 * @LastEditors: zhoucheng
 */

// 下拉接口
import * as queryDict from './queryDict/queryDict.js'
// 停车 停车场查询
import * as carParking from './carParking/carParking.js'
// 反向寻车
import * as reverseSearch from './carParking/reverseSearch.js'

// 首页-代缴车费（车牌绑定）
import * as payForBehalf from './homePage/payForBehalf.js'
// 首页-停车缴费-停车代缴-订单详情
import * as payForParkCount from './homePage/payForParkCount.js'
// 首页-停车缴费-停车代缴-订单详情
import * as payForBehalfOnline from './homePage/payForBehalfOnline.js'
// 首页-停车缴费-车位停车代缴
import * as payForSpacePark from './homePage/payForSpacePark.js'

// 首页-订单申诉
import * as orderComplain from './homePage/orderComplain.js'
// 首页-欠费订单
import * as lackOrder from './homePage/lackOrder.js'
// 支付信息 payInfo
import * as payInfo from './homePage/payInfo.js'
// 我的-车辆管理
import * as carManagement from './myself/carManagement.js'
// 我的-车辆管理-车牌绑定
import * as plateBind from './myself/plateBind.js'
// 我的-反馈建议
import * as problemFeedBack from './myself/problemFeedBack.js'
// 我的-反馈建议-我要反馈
import * as myFeedBack from './myself/myFeedBack.js'
// 我的-反馈建议-反馈详情
import * as feedBackDetails from './myself/feedBackDetails.js'
// 我的 帮助中心
import * as helpCenter from './myself/helpCenter.js'
// 我的 个人信息详情
import * as selfInfoDetails from './myself/selfInfoDetails.js'
// 我的 个人信息
import * as selfInfo from './myself/selfInfo.js'
// 底部我的-个人信息-手机号修改
import * as selfChangePhone from './myself/selfChangePhone.js'
// 底部我的-个人信息-实名认证
import * as authenticateSelf from './myself/authenticateSelf.js'

// 支付详情-计费规则
import * as biliingRules from './homePage/biliingRules.js'

// 支付绑定
import * as choosePayStyle from './payBind/choosePayStyle.js'
// 计费规则详情  billRuleDetail
import * as billRuleDetail from './payBind/billRuleDetail.js'
// 首页资讯管理
import * as homeIndex from './homePage/homeIndex.js'

// 开具发票
// 开票历史
import * as invoiceHistory from './invoiceCenter/invoiceHistory.js'
// 停车开票
import * as parkInvoice from './invoiceCenter/parkInvoice.js'

const install = function (Vue) {
  if (install.installed) {
    return (install.installed = true)
  }
  Object.defineProperties(Vue.prototype, {
    $choosePayStyle: {
      get () {
        return choosePayStyle
      }
    },
    $queryDict: {
      get () {
        return queryDict
      }
    },
    $payForBehalf: {
      get () {
        return payForBehalf
      }
    },
    $carManagement: {
      get () {
        return carManagement
      }
    },
    $plateBind: {
      get () {
        return plateBind
      }
    },
    $payForParkCount: {
      get () {
        return payForParkCount
      }
    },
    $biliingRules: {
      get () {
        return biliingRules
      }
    },
    $payForBehalfOnline: {
      get () {
        return payForBehalfOnline
      }
    },
    $billRuleDetail: {
      get () {
        return billRuleDetail
      }
    },
    $feedBackDetails: {
      get () {
        return feedBackDetails
      }
    },
    $myFeedBack: {
      get () {
        return myFeedBack
      }
    },
    $problemFeedBack: {
      get () {
        return problemFeedBack
      }
    },
    $helpCenter: {
      get () {
        return helpCenter
      }
    },
    $carParking: {
      get () {
        return carParking
      }
    },
    $homeIndex: {
      get () {
        return homeIndex
      }
    },
    $orderComplain: {
      get () {
        return orderComplain
      }
    },
    $selfInfoDetails: {
      get () {
        return selfInfoDetails
      }
    },
    $selfInfo: {
      get () {
        return selfInfo
      }
    },
    $payInfo: {
      get () {
        return payInfo
      }
    },
    $invoiceHistory: {
      get () {
        return invoiceHistory
      }
    },
    $parkInvoice: {
      get () {
        return parkInvoice
      }
    },
    $reverseSearch: {
      get () {
        return reverseSearch
      }
    },
    $lackOrder: {
      get () {
        return lackOrder
      }
    },
    $selfChangePhone: {
      get () {
        return selfChangePhone
      }
    },
    $authenticateSelf: {
      get () {
        return authenticateSelf
      }
    },
    $payForSpacePark: {
      get () {
        return payForSpacePark
      }
    }
  })
}

export default {
  install
}
