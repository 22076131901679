/*
 * @Description:我的 车辆管理列表 carManagement
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-22 15:14:56
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/mobile'

// 样例请求post
export function demoPost (param) {
  return request({
    url: prefix + '/demo',
    method: 'post',
    data: param
  })
}
