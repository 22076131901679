/*
 * @Description:
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-26 09:40:12
 * @LastEditors: haojing
 */
import request from '@/utils/request'

const prefix = '/operate'
// 车牌颜色下拉框查询
export function queryDict (param) {
  return request({
    url: prefix + '/dict/queryDict',
    method: 'post',
    data: param
  })
}
