/*
 * @Description: 请求封装
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 20:55:29
 * @LastEditors: zhoucheng
 */

// import store from '@/store'
import axios from 'axios'
import { Toast } from 'vant'
import router from '../router'
import getUserInfo from '@/utils/getUserInfo.js'

// create an axios instance
const service = axios.create({
  // baseURL: 'https://esalotto.cn/cq-parking-h5/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  baseURL: '/cqparkApi', // url = base url + request url
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // 获取安卓端的userToken
    if (window.android) {
      config.headers.userToken = getUserInfo().userToken
    }
    // config.headers.userToken = 'dddb8f5f81af52ac6bd558eac4a25dcf'
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.resultCode === '3004') {
      router.push('/login')
    }
    if (res.resultCode !== '2000') {
      // 错误信息提示
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      if (res.resultCode === '0') {
        window.android.reLogin()
      }
      Toast(res.resultMsg)
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    return Promise.reject(error)
  }
)

export default service
