/*
 * @Description:用户端-底部我的-反馈建议 problemFeedBack
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-24 12:51:55
 * @LastEditors: LinFeng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 样例请求post
export function demoPost (param) {
  return request({
    url: prefix + '/demo',
    method: 'post',
    data: param
  })
}

// 获取反馈建议
export function queryFeedBackList (param) {
  return request({
    url: prefix + '/feedback/queryFeedBackListWithAuth',
    // url: prefix + '/feedback/queryFeedBackList',
    method: 'post',
    data: param
  })
}
