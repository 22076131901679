/*
 * @Description:底部我的-个人信息-手机号修改 selfChangePhone
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-31 10:37:08
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/demo'

// 样例请求
export function demo (param) {
  return request({
    url: prefix + '/demo',
    method: 'post',
    data: param
  })
}
