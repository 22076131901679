/*
 * @Description:我的 个人信息详情 selfInfoDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-04 14:56:10
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/demo'

// 样例请求post
export function demoPost (param) {
  return request({
    url: prefix + '/demo',
    method: 'post',
    data: param
  })
}
