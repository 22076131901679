/*
 * @Description:
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-03 11:16:45
 * @LastEditors: yanxiao
 */
import request from '@/utils/request'

const prefix = '/operate'

// 根据计费ID查计费规则详情
export function queryInformation (param) {
  return request({
    url: prefix + '/ContentController/queryContentList',
    method: 'post',
    data: param
  })
}

// 轮播图查询
export function queryAdvertiseList (param) {
  return request({
    url: prefix + '/AdvertiseController/queryAdvertiseList',
    method: 'post',
    data: param
  })
}
