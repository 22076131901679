/*
 * @Description: 基础应用store
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 21:48:57
 * @LastEditors: John Holl
 */

// const state = {
//   sidebar: {
//     opened: localStorage.get('sidebarStatus') ? !!+localStorage.get('sidebarStatus') : true,
//     withoutAnimation: false
//   },
//   device: 'desktop',
//   size: localStorage.get('size') || 'medium'
// }

const state = {
  coordinate: localStorage.getItem('coordinate') || 'fff'
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      localStorage.set('sidebarStatus', 1)
    } else {
      localStorage.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    localStorage.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  SET_COORDINATE: (state, coordinate) => {
    state.coordinate = coordinate
    localStorage.setItem('coordinate', coordinate)
  }
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar ({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  setCoordinate ({ commit }, coordinate) {
    commit('SET_COORDINATE', coordinate)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
