/*
 * @Description:订单申诉 orderComplain
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-04 08:56:45
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'

// 订单申诉
export function appeal (param) {
  return request({
    url: prefix + '/OrderAppeal/appeal',
    method: 'post',
    data: param
  })
}
