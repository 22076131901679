/*
 * @Description:
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-05-19 11:18:02
 * @LastEditors: zhoucheng
 */

/**
 * loginType:
 * WX 微信
 * ZFB 支付宝
 * PPHONE 手机号
 *
 * avatar: 头像
 */

const IP = 'http://123.207.189.27:7201'

export default function () {
  let userInfo = {}
  if (window.android) {
    const androidInfo = JSON.parse(window.android.getUserInfo())
    const loginJson = JSON.parse(androidInfo.loginJson)
    userInfo.loginType = androidInfo.loginType
    userInfo.baseObjId = androidInfo.baseObjId
    for (const key in loginJson) {
      if (key === 'avatar') {
        userInfo.avatar = IP + loginJson.avatar
        console.log(userInfo.avatar, 'touxiangtouxiang')
      } else {
        userInfo[key] = loginJson[key]
      }
    }
  } else if (window.webkit) {
    const userInfoStr = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfoStr)
    console.log('iosUserInfo', userInfo)
  } else {
    userInfo = {
      userCode: '13270283360',
      userToken: 'f50c6901f4eb34f6b59f1d5f92001d42',
      phoneNumber: '未登录',
      userName: '未登录',
      Email: '未登录',
      userSex: '男'
    }
  }
  return userInfo
}

/**
 * ios 用户信息
 * {
  pageSize: '0',
  loginErrNum: '0',
  userAge: '0',
  pageNum: '0',
  userType: '0',
  departId: '0',
  editFlag: 'true',
  passWord: '670b14728ad9902aecba32e22fa4f6bd',
  loginErrLimit: '0',
  userName: '新用户',
  userStatus: '0',
  userToken: 'eaa532d0fa188ab7f3fac107644fc0e7',
  sessionTime: '0',
  lockLimitTime: '0',
  userCode: '17368011397',
  phoneNumber: '17368011397',
  userSex: '0'
}
 */
