/*
 * @Description: 用户端 请求样例
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 20:44:48
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'

// 微信支付
export function wxAppPay (param) {
  return request({
    // url: prefix + '/test/testAppPay',
    url: prefix + '/wxPay/appPay',
    method: 'post',
    data: param
  })
}

// 支付宝支付
export function zfbAliPay (param) {
  return request({
    // url: prefix + '/test/testAliPay',
    url: prefix + '/aliPay/aliAppPay',
    method: 'post',
    data: param
  })
}

// wx支付回调
export function queryOrderPayResult (param) {
  return request({
    url: prefix + '/test/queryOrderPayResult',
    method: 'post',
    data: param
  })
}
