/*
 * @Description:开票历史 invoiceHistory
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-11 10:46:34
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/mobile'

// 获取开票记录
export function queryInvoiceRecord (param) {
  return request({
    url: prefix + '/invoice/queryInvoiceRecord',
    method: 'post',
    data: param
  })
}
// 发票反查支付记录
export function getPaymentRecordByInvoice (param) {
  return request({
    url: prefix + '/payment/getPaymentRecordByInvoice',
    method: 'post',
    data: param
  })
}
export function getOrderByPayment (param) {
  return request({
    url: prefix + '/payment/getOrderByPayment',
    method: 'post',
    data: param
  })
}
