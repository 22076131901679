/*
 * @Description:我的 帮助中心 helpCenter
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-25 09:27:07
 * @LastEditors: LinFeng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 样例请求post
export function demoPost (param) {
  return request({
    url: prefix + '/demo',
    method: 'post',
    data: param
  })
}
// 查询帮助中心列表
export function querySupportList (param) {
  return request({
    url: prefix + '/support/querySupportList',
    method: 'post',
    data: param
  })
}
