import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    redirect: '/homePage',
    meta: {
      name: '重定向页面'
    }
  },
  // 用户端-登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      name: '用户端-登录页',
      type: 'list'
    }
  },
  // 用户端-测试页
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/test/index.vue'),
    meta: {
      name: '用户端-登录页',
      type: 'list'
    }
  },
  // 判断userToken是否为空
  {
    path: '/awareUserToken',
    name: 'awareUserToken',
    component: () => import('@/views/awareUserToken/index.vue'),
    meta: {
      name: '判断userToken是否为空',
      type: 'list'
    }
  },
  //  用户端-底部首页
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import('@/views/homePage/index.vue'),
    meta: {
      name: '用户端-底部首页',
      type: 'list'
    }
  },
  // 首页-资讯详情页面
  {
    path: '/informationDetails',
    name: 'InformationDetails',
    component: () => import('@/views/homePage/informationDetails/index.vue'),
    meta: {
      name: '用户端-资讯详情',
      type: 'list'
    }
  },
  //  用户端-订单申诉
  {
    path: '/orderComplain',
    name: 'orderComplain',
    component: () => import('@/views/homePage/orderComplain/index.vue'),
    meta: {
      name: '用户端-订单申诉',
      type: 'list'
    }
  },

  //  用户端-首页-停车缴费
  {
    path: '/parForParking',
    name: 'parForParking',
    component: () => import('@/views/homePage/payForParking/index.vue'),
    meta: {
      name: '用户端-首页-停车缴费',
      type: 'list'
    }
  },
  // 用户端-首页-停车缴费-停车代缴
  {
    path: '/payForBehalf',
    name: 'payForBehalf',
    component: () => import('@/views/homePage/payForParking/payForBehalf/index.vue'),
    meta: {
      name: '用户端-首页-停车缴费-停车代缴',
      type: 'list'
    }
  },
  // 用户端-首页-停车缴费-停车代缴-在线订单
  {
    path: '/payForBehalfOnline',
    name: 'payForBehalfOnline',
    component: () => import('@/views/homePage/payForParking/payForBehalf/payForBehalfOnline/index.vue'),
    meta: {
      name: ' 用户端-首页-停车缴费-停车代缴-在线订单',
      type: 'list'
    }
  },
  // 用户端-首页-停车缴费-停车代缴-停车支付
  {
    path: '/parForParkCount',
    name: 'parForParkCount',
    component: () => import('@/views/homePage/payForParking/parForParkCount/index.vue'),
    meta: {
      name: '用户端-首页-停车缴费-停车代缴-停车支付',
      type: 'list'
    }
  },
  // 用户端-首页-停车缴费-车位查询代缴
  {
    path: '/payForSpacePark',
    name: 'payForSpacePark',
    component: () => import('@/views/homePage/payForParking/payForSpacePark/index.vue'),
    meta: {
      name: '用户端-首页-停车缴费-车位查询代缴',
      type: 'list'
    }
  },
  {
    path: '/payForSpaceParkOnline',
    name: 'payForSpaceParkOnline',
    component: () => import('@/views/homePage/payForParking/payForSpacePark/payForSpaceParkOnline/index.vue'),
    meta: {
      name: '用户端-首页-停车缴费-车位查询代缴-在线订单',
      type: 'list'
    }
  },
  //  用户端-首页-周边泊位
  {
    path: '/aroundParkSpace',
    name: 'aroundParkSpace',
    component: () => import('@/views/homePage/aroundParkSpace/index.vue'),
    meta: {
      name: '用户端-首页-周边泊位',
      type: 'list'
    }
  },
  //  用户端-首页-周边泊位-详情
  {
    path: '/aroundParkInfo',
    name: 'aroundParkInfo',
    component: () => import('@/views/homePage/aroundParkSpace/aroundParkInfo/index.vue'),
    meta: {
      name: '用户端-首页-周边泊位-详情',
      type: 'list'
    }
  },
  // 用户端-欠费订单
  {
    path: '/lackOrder',
    name: 'lackOrder',
    component: () => import('@/views/homePage/lackOrder/index.vue'),
    meta: {
      name: '用户端-首页-停车记录',
      type: 'list'
    }
  },
  // 用户端-欠费订单详情
  {
    path: '/lackOrderDetail',
    name: 'lackOrderDetail',
    component: () => import('@/views/homePage/lackOrder/lackOrderDetail/index.vue'),
    meta: {
      name: '用户端-首页-停车记录',
      type: 'list'
    }
  },
  //  用户端-首页-停车记录
  {
    path: '/parkingRecord',
    name: 'parkingRecord',
    component: () => import('@/views/homePage/parkingRecord/index.vue'),
    meta: {
      name: '用户端-首页-停车记录',
      type: 'list'
    }
  },
  //  用户端-首页-停车记录-详情
  {
    path: '/parkingRecordInfo',
    name: 'parkingRecordInfo',
    component: () => import('@/views/homePage/parkingRecord/parkingRecordInfo/index.vue'),
    meta: {
      name: '用户端-首页-停车记录-详情',
      type: 'list'
    }
  },
  //  用户端-首页-支付信息
  {
    path: '/payInfo',
    name: 'payInfo',
    component: () => import('@/views/homePage/payInfo/index.vue'),
    meta: {
      name: '用户端-首页-支付信息',
      type: 'list'
    }
  },
  //  用户端-首页-支付信息-支付详情
  {
    path: '/payInfoInfo',
    name: 'payInfoInfo',
    component: () => import('@/views/homePage/payInfo/payInfoInfo/index.vue'),
    meta: {
      name: '用户端-首页-支付信息-详情',
      type: 'list'
    }
  },
  // 用户端-首页-共享停车
  {
    path: '/sharePark',
    name: 'sharePark',
    component: () => import('@/views/homePage/sharePark/index.vue'),
    meta: {
      name: '用户端-首页-共享停车',
      type: 'list'
    }
  },
  // 用户端-首页-共享停车-共享发布
  {
    path: '/shareIssue',
    name: 'shareIssue',
    component: () => import('@/views/homePage/sharePark/shareIssue/index.vue'),
    meta: {
      name: '用户端-首页-共享停车-共享发布',
      type: 'list'
    }
  },
  // 用户端-首页-更多服务
  {
    path: '/moreService',
    name: 'moreService',
    component: () => import('@/views/moreService/index.vue'),
    meta: {
      name: '用户端-首页-更多服务',
      type: 'list'
    }
  },
  // 用户端-首页-更多服务-洗车
  {
    path: '/washCarIndex',
    name: 'washCarIndex',
    component: () => import('@/views/moreService/washCarIndex/index.vue'),
    meta: {
      name: '用户端-首页-更多服务-洗车',
      type: 'list'
    }
  },
  // 用户端-首页-更多服务-洗车-支付选择
  {
    path: '/washPayStyle',
    name: 'washCarIndex',
    component: () => import('@/views/moreService/washCarIndex/washPayStyle/index.vue'),
    meta: {
      name: '用户端-首页-更多服务-洗车-支付选择',
      type: 'list'
    }
  },
  // 用户端-首页-更多服务-洗车-洗车状态
  {
    path: '/washCarStatus',
    name: 'washCarStatus',
    component: () => import('@/views/moreService/washCarIndex/washCarStatus/index.vue'),
    meta: {
      name: '用户端-首页-更多服务-洗车-洗车状态',
      type: 'list'
    }
  },
  // 用户端-首页-更多服务-加油
  {
    path: '/refuelCar',
    name: 'refuelCar',
    component: () => import('@/views/moreService/refuelCar/index.vue'),
    meta: {
      name: '用户端-首页-更多服务-加油',
      type: 'list'
    }
  },
  // 用户端-首页-更多服务-充电
  {
    path: '/chargeCar',
    name: 'chargeCar',
    component: () => import('@/views/moreService/chargeCar/index.vue'),
    meta: {
      name: '用户端-首页-更多服务-充电',
      type: 'list'
    }
  },
  //  用户端-底部停车
  {
    path: '/carParking',
    name: 'carParking',
    component: () => import('@/views/carParking/index.vue'),
    meta: {
      name: '用户端-底部停车',
      type: 'list'
    }
  },
  //  用户端-底部停车-停车地图详情
  {
    path: '/carParkingInfo',
    name: 'carParkingInfo',
    component: () => import('@/views/carParking/carParkingInfo/index.vue'),
    meta: {
      name: '用户端-底部停车-停车地图详情',
      type: 'list'
    }
  },
  //  用户端-底部停车-反向寻车
  {
    path: '/reverseSearch',
    name: 'reverseSearch',
    component: () => import('@/views/carParking/reverseSearch/index.vue'),
    meta: {
      name: '用户端-底部停车-停车地图详情',
      type: 'list'
    }
  },
  //  用户端-底部我的
  {
    path: '/myself',
    name: 'myself',
    component: () => import('@/views/myself/index.vue'),
    meta: {
      name: '用户端-底部我的',
      type: 'list'
    }
  },
  // 用户端-查询车辆列表
  {
    path: '/queryCarList',
    name: 'queryCarList',
    component: () => import('@/views/myself/queryCarList/index.vue'),
    meta: {
      name: '用户端-底部我的',
      type: 'list'
    }
  },
  //  用户端-底部我的-发布管理
  {
    path: '/noticeManage',
    name: 'noticeManage',
    component: () => import('@/views/myself/noticeManage/index.vue'),
    meta: {
      name: '用户端-底部我的-发布管理',
      type: 'list'
    }
  },
  //  用户端-底部我的-车辆管理
  {
    path: '/carManagement',
    name: 'carManagement',
    component: () => import('@/views/myself/carManagement/index.vue'),
    meta: {
      name: '用户端-底部我的-车辆管理',
      type: 'list'
    }
  },
  //  用户端-底部我的-车辆管理-绑定车牌
  {
    path: '/plateBind',
    name: 'plateBind',
    component: () => import('@/views/myself/carManagement/plateBind/index.vue'),
    meta: {
      name: '用户端-底部我的-车辆管理-绑定车牌',
      type: 'list'
    }
  },
  //  用户端-底部我的-问题反馈
  {
    path: '/problemFeedBack',
    name: 'problemFeedBack',
    component: () => import('@/views/myself/problemFeedBack/index.vue'),
    meta: {
      name: '用户端-底部我的-问题反馈',
      type: 'list'
    }
  },
  //  用户端-底部我的-问题反馈-我的反馈
  {
    path: '/myFeedBack',
    name: 'myFeedBack',
    component: () => import('@/views/myself/problemFeedBack/myFeedBack/index.vue'),
    meta: {
      name: '用户端-底部我的-我的反馈',
      type: 'list'
    }
  },
  //  用户端-底部我的-问题反馈-反馈详情
  {
    path: '/feedBackDetails',
    name: 'feedBackDetails',
    component: () => import('@/views/myself/problemFeedBack/feedBackDetails/index.vue'),
    meta: {
      name: '用户端-底部我的-问题反馈-反馈详情',
      type: 'list'
    }
  },
  //  用户端-底部我的-帮助中心
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: () => import('@/views/myself/helpCenter/index.vue'),
    meta: {
      name: '用户端-底部我的-帮助中心',
      type: 'list'
    }
  },
  //  用户端-底部我的-帮助中心-内容
  {
    path: '/helpContent',
    name: 'helpContent',
    component: () => import('@/views/myself/helpCenter/helpContent/index.vue'),
    meta: {
      name: '用户端-底部我的-帮助中心-内容',
      type: 'list'
    }
  },
  //  用户端-底部我的-关于我们
  {
    path: '/aboutOur',
    name: 'aboutOur',
    component: () => import('@/views/myself/aboutOur/index.vue'),
    meta: {
      name: '用户端-底部我的-关于我们',
      type: 'list'
    }
  },
  //  用户端-底部我的-关于我们-隐私政策
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/myself/aboutOur/privacyPolicy/index.vue'),
    meta: {
      name: '用户端-底部我的-关于我们-隐私政策',
      type: 'list'
    }
  },
  //  用户端-底部我的-关于我们-用户协议
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import('@/views/myself/aboutOur/userAgreement/index.vue'),
    meta: {
      name: '用户端-底部我的-关于我们-用户协议',
      type: 'list'
    }
  },
  //  用户端-底部我的-关于我们-隐私政策 单个页面
  {
    path: '/privacyPolicySingle',
    name: 'privacyPolicySingle',
    component: () => import('@/views/myself/aboutOur/privacyPolicySingle/index.vue'),
    meta: {
      name: '用户端-底部我的-关于我们-隐私政策',
      type: 'list'
    }
  },
  //  用户端-底部我的-关于我们-用户协议 单个页面
  {
    path: '/userAgreementSingle',
    name: 'userAgreementSingle',
    component: () => import('@/views/myself/aboutOur/userAgreementSingle/index.vue'),
    meta: {
      name: '用户端-底部我的-关于我们-用户协议',
      type: 'list'
    }
  },
  //  用户端-底部我的-通知
  {
    path: '/inform',
    name: 'inform',
    component: () => import('@/views/myself/inform/index.vue'),
    meta: {
      name: '用户端-底部我的-通知',
      type: 'list'
    }
  },
  //  用户端-支付绑定
  {
    path: '/payBind',
    name: 'payBind',
    component: () => import('@/views/payBind/index.vue'),
    meta: {
      name: '用户端-底部我的-通知',
      type: 'list'
    }
  },
  //  用户端-支付方式选择
  {
    path: '/choosePayStyle',
    name: 'choosePayStyle',
    component: () => import('@/views/payBind/choosePayStyle/index.vue'),
    meta: {
      name: '用户端-支付方式选择',
      type: 'list'
    }
  },
  // 用户端-计费规则详情页
  {
    path: '/billRuleDetail',
    name: 'billRuleDetail',
    component: () => import('@/views/payBind/billRuleDetail/index.vue'),
    meta: {
      name: '用户端-支付方式选择',
      type: 'list'
    }
  },
  //  用户端-底部我的-个人信息
  {
    path: '/selfInfo',
    name: 'selfInfo',
    component: () => import('@/views/myself/selfInfo/index.vue'),
    meta: {
      name: '用户端-底部我的-个人信息',
      type: 'list'
    }
  },
  //  用户端-底部我的-个人信息详情
  {
    path: '/selfInfoDetails',
    name: 'selfInfoDetails',
    component: () => import('@/views/myself/selfInfo/selfInfoDetails/index.vue'),
    meta: {
      name: '用户端-底部我的-个人信息详情',
      type: 'list'
    }
  },
  // 用户端-底部我的-个人信息-手机号修改
  {
    path: '/selfChangePhone',
    name: 'selfChangePhone',
    component: () => import('@/views/myself/selfInfo/selfChangePhone/index.vue'),
    meta: {
      name: '用户端-底部我的-个人信息-实名认证',
      type: 'list'
    }
  },
  // 用户端-底部我的-个人信息-实名认证
  {
    path: '/authenticateSelf',
    name: 'authenticateSelf',
    component: () => import('@/views/myself/authenticateSelf/index.vue'),
    meta: {
      name: '用户端-底部我的-个人信息-实名认证',
      type: 'list'
    }
  },
  /**
   * 发票
   */
  // 发票中心
  {
    path: '/invoiceCenter',
    name: 'invoiceCenter',
    component: () => import('@/views/invoiceCenter/index.vue'),
    meta: {
      name: '用户端-开发票-发票中心',
      type: 'list'
    }
  },
  // 用户端-发票中心-停车费用开票-支付记录
  {
    path: '/paymentHistory',
    name: 'paymentHistory',
    component: () => import('@/views/invoiceCenter/parkInvoice/paymentHistory/index.vue'),
    meta: {
      name: '用户端-发票中心-停车费用开票-支付记录',
      type: 'list'
    }
  },
  // 用户端-发票中心-停车费用开票-填写发票信息
  {
    path: '/fillInvoice',
    name: 'fillInvoice',
    component: () => import('@/views/invoiceCenter/parkInvoice/fillInvoice/index.vue'),
    meta: {
      name: '用户端-发票中心-停车费用开票-填写发票信息',
      type: 'list'
    }
  },
  // 用户端-发票中心-开票记录
  {
    path: '/invoiceHistory',
    name: 'invoiceHistory',
    component: () => import('@/views/invoiceCenter/invoiceHistory/index.vue'),
    meta: {
      name: '用户端-发票中心-开票记录',
      type: 'list'
    }
  },
  // 用户端-发票中心-开票记录-发票详情
  {
    path: '/invoiceDetails',
    name: 'invoiceDetails',
    component: () => import('@/views/invoiceCenter/invoiceHistory/invoiceDetails/index.vue'),
    meta: {
      name: '用户端-发票中心-发票详情',
      type: 'list'
    }
  },
  // 用户端-发票中心-开票记录-发票详情-发票文件查看
  {
    path: '/invoiceFile',
    name: 'invoiceFile',
    component: () => import('@/views/invoiceCenter/invoiceHistory/invoiceFile/index.vue'),
    meta: {
      name: '用户端-发票中心-发票详情-发票文件查看',
      type: 'list'
    }
  },
  // 用户端-发票中心-开票记录-发票详情-发票支付详情列表
  {
    path: '/invoicePayList',
    name: 'invoicePayList',
    component: () => import('@/views/invoiceCenter/invoiceHistory/invoicePayList/index.vue'),
    meta: {
      name: '用户端-发票中心-发票详情-发票支付详情列表',
      type: 'list'
    }
  }
]

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL
})

export default router
