/*
 * @Description:用户端-首页-停车缴费-车位查询代缴
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-11 16:11:48
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'

// 按照车位查询订单
export function queryOrderOnlineByParkSpaceId (param) {
  return request({
    url: prefix + '/OrderController/queryOrderOnlineByParkSpaceId',
    method: 'post',
    data: param
  })
}
