/*
 * @Description:支付信息 payInfo
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-11 09:27:42
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/mobile'

// 获取支付信息
export function getPaymentRecord (param) {
  return request({
    url: prefix + '/payment/getPaymentRecord',
    method: 'get',
    data: param
  })
}

// 获取支付详情
export function getOrderByPayment (param) {
  return request({
    url: prefix + '/payment/getOrderByPayment',
    method: 'post',
    data: param
  })
}
