/*
 * @Description:用户端-首页-停车缴费-停车代缴-在线订单 payForBehalfOnline
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-05 21:30:06
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'
// 查询订单详情
export function queryOnlineOrderBySinglePlateNumber (param) {
  return request({
    url: prefix + '/OrderController/queryOnlineOrderBySinglePlateNumber',
    method: 'post',
    data: param
  })
}
