/*
 * @Description:
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-15 11:13:14
 * @LastEditors: linf
 */

const publicVar = {
  install (Vue) {
    // 把当前的对象挂载到vue的全局
    Vue.prototype.$imgBaseUrl = this.imgBaseUrl
    Vue.prototype.$pdfBaseUrl = this.pdfBaseUrl
    Vue.prototype.$headPhotoUrl = this.headPhotoUrl
  },

  // 图片baseurl
  imgBaseUrl: 'https://gateway.cqybxm.com/operate/upload/imgs/',
  // imgBaseUrl: 'https://esalotto.cn/cqparkApi/operate/upload/imgs/',
  // imgBaseUrl: 'https://epark.zsparking.com/cqparkApi/operate/upload/imgs/',

  pdfBaseUrl: 'https://gateway.cqybxm.com/operate/invoice/',
  // pdfBaseUrl: 'https://esalotto.cn/cqparkApi/operate/invoice/',
  // pdfBaseUrl: 'https://epark.zsparking.com/cqparkApi/operate/invoice/'

  headPhotoUrl: 'https://gateway.cqybxm.com'

}

export default publicVar
